// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-explorer-index-js": () => import("./../../../src/pages/explorer/index.js" /* webpackChunkName: "component---src-pages-explorer-index-js" */),
  "component---src-pages-explorer-search-js": () => import("./../../../src/pages/explorer/search.js" /* webpackChunkName: "component---src-pages-explorer-search-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-live-js": () => import("./../../../src/pages/live.js" /* webpackChunkName: "component---src-pages-live-js" */),
  "component---src-pages-mint-cardano-tokens-js": () => import("./../../../src/pages/mint-cardano-tokens.js" /* webpackChunkName: "component---src-pages-mint-cardano-tokens-js" */),
  "component---src-pages-top-nft-projects-js": () => import("./../../../src/pages/top-nft-projects.js" /* webpackChunkName: "component---src-pages-top-nft-projects-js" */)
}

