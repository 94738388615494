const actions = {
  SET_STATE: "settings/SET_STATE",
  CHANGE_SETTING: "settings/CHANGE_SETTING",
  CHANGE_STORE_SESSION: "settings/CHANGE_STORE_SESSION",
  LOAD_APP: "settings/LOAD_APP",
  GET_BALANCE: "settings/GET_BALANCE",
  GENERATE_NEW_SESSION: "settings/GENERATE_NEW_SESSION",
  FETCH_NETWORK_STATE: "settings/FETCH_NETWORK_STATE",
  SWITCH_MEGA_MENU: "settings/SWITCH_MEGA_MENU",
  CHANGE_THEME: "settings/CHANGE_THEME",
}

export default actions
